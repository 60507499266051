<template>
  <div class="dd-home-zy">
    <h3>钉钉专业版</h3>
    <p>助力企业打造属于自己的数字化资产</p>
    <ul>
      <li v-for="(item, index) in list" :key="index" class="iconfont icon-gouxuan">{{ item }}</li>
    </ul>
    <div class="btn-list">
      <button @click="openService">立即咨询</button>
      <button @click="$router.push('/cloud/dingtalk/zy')">了解详情</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        '统一存储1TB',
        '聊天记录云端保存时效延长',
        '沟通安全之保密群',
        '组织安全之数据恢复',
        '自定义工作台',
        '考勤专业版',
        'OA审批专业版',
        '视频会议专业版',
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.dd-home-zy {
  padding: 50px 15px;
  color: #383838;

  >h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }

  >ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -10px;

    >li {
      width: 50%;
      font-size: 14px;
      height: 100%;
      margin: 0;
      padding: 10px;
      padding-left: 30px;
      position: relative;

      &::before {
        position: absolute;
        left: 10px;
        line-height: 1.5;
        color: #E80B10;
      }
    }
  }

  .btn-list {
    display: flex;
    margin-top: 40px;

    >button {
      width: 50%;
      margin: 0 10px;
      border: 0 none;
      background: none;
      appearance: none;
      border-radius: 3px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        background-color: #E80B10;
        color: #fff;
      }

      &:last-child {
        border: 1px solid #E80B10;
        color: #E80B10;
      }
    }
  }
}
</style>