<template>
  <div class="data-value">
    <h3>企业专属钉钉</h3>
    <h4>助力企业打造专属、安全、开放的数字化办公运营平台，定义企业自己的数字化工作方式</h4>
    <p>在钉钉强大的组织、沟通、协同、业务和生态在线的基座上助力企业打造专属、安全、开放的数字化办公运营平台<br /><br />
      专属设计，为企业提供文化共振服务，帮助企业打造归属感<br /><br />
      专属安全，为企业提供安全托管服务和数字资产管理服务，针对有特殊安全需要的企业和组织，提供更加个性化的安全管控能力<br /><br />
      专属开放，为企业提供业务链接服务，助力客户实现多业务系统的深度融合</p>
    <div class="btn-list">
      <button @click="openService">立即咨询</button>
      <button @click="$router.push('/cloud/dingtalk/zs')">了解详情</button>
    </div>
  </div>
</template>
<script></script>
<style lang="less" scoped>
.data-value {
  padding: 60px 30px;
  background: url(/static/img/plan-back.jpg) no-repeat center;
  background-size: cover;
  color: #fff;

  >h3 {
    // text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  >h4 {
    font-size: 15px;
    font-weight: 300;
    margin-top: 15px;
    text-align: center;
    padding-bottom: 70px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 15px;
      height: 2px;
      background-color: #fff;
      transform: translateX(-50%);
    }
  }

  >p {
    font-size: 15px;
    font-weight: 300;
    line-height: 2.5;
    text-align: justify;
  }

  .btn-list {
    display: flex;
    margin-top: 40px;

    >button {
      width: 50%;
      margin: 0 10px;
      border: 0 none;
      background: none;
      appearance: none;
      border-radius: 3px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        background-color: #E80B10;
        color: #fff;
      }

      &:last-child {
        border: 1px solid #E80B10;
        color: #E80B10;
      }
    }
  }
}
</style>