<template>
  <div class="dd-banner" @click="openService">
    <img src="/static/img/banner-dd.jpg" />
    <!-- <h3>企业专属钉钉</h3>
    <p>助力企业打造专属、安全、开放的<br/>数字化办公运营平台，<br/>定义企业自己的数字化工作学习方式</p>
    <button @click="openService">立即咨询</button> -->
  </div>
  <ul class="dd-home-nav">
    <li @click="handleNav('zy')" :class="{ active: active == 'zy' }">专业版</li>
    <li @click="handleNav('zs')" :class="{ active: active == 'zs' }">专属版</li>
    <li @click="handleNav('zy2')" :class="{ active: active == 'zy2' }">专有版</li>
  </ul>
  <HomeZY id="zy" />
  <HomeZS id="zs" />
  <HomeZY2 id="zy2" />
</template>
<script>
import HomeZY from '../../components/dingtalk/HomeZY.vue';
import HomeZS from '../../components/dingtalk/HomeZS.vue';
import HomeZY2 from '../../components/dingtalk/HomeZY2.vue';
export default {
  components: {
    HomeZY,
    HomeZS,
    HomeZY2
  },
  data() {
    return {
      active: 'zy'
    }
  },
  mounted() {
    window.listenerFun = this.listener;
    window.addEventListener('scroll', window.listenerFun);
  },
  beforeUnmount: () => {
    window.removeEventListener('scroll', window.listenerFun);
  },
  methods: {
    handleNav(id) {
      window.scrollTo(0, document.getElementById(id).offsetTop - 100)
    },
    handleActive(scrollTop) {
      if (this.timer != null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (scrollTop >= document.getElementById('zy2').offsetTop - 100) {
          this.active = 'zy2'
        } else if (scrollTop >= document.getElementById('zs').offsetTop - 100) {
          this.active = 'zs'
        } else {
          this.active = 'zy'
        }
      }, 100)
    },
    listener(e) {
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      this.handleActive(scrollTop);
    }
  }
}
</script>
<style lang="less" scoped>
.dd-banner {

  // padding: 65px 25px;
  // background: url(/static/img/dd-banner.jpg) no-repeat center;
  // background-size: cover;
  // color: #fff;
  >img {
    width: 100%;
    display: block;
  }

  >h3 {
    font-size: 20px;
    font-weight: bold;
  }

  >p {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8;
    margin-top: 10px;
  }

  >button {
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}

ul.dd-home-nav {
  position: sticky;
  top: 48px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, .3);
  z-index: 3;
  height: 48px;

  >li {
    flex: 1;
    text-align: center;
    font-size: 15px;
    position: relative;
    height: 100%;
    line-height: 48px;

    &.active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      height: 2px;
      background-color: #F23D49;
    }
  }
}
</style>
